import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const baseURI = "https://expense-tracker-server-qaev.onrender.com"
export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({baseUrl: baseURI}),
    endpoints: builder =>({
        // get categories
        getCategories: builder.query({
            query: ()=> '/api/categories',
            providesTags: ['categories']
        }),
        // Get labels
        getLabels: builder.query({
            query: ()=> '/api/labels',
            providesTags: ['transaction']
        }),
        // add new transaction
        addTransaction: builder.mutation({
            query: (initialTransaction)=> ({
                url: '/api/transaction',
                method: 'POST',
                body: initialTransaction
            }),
            invalidatesTags: ['transaction']
        }),
        // Delete record
        deleteTransaction: builder.mutation({
            query: (record_id)=>({
                url: "/api/transaction",
                method: "delete",
                body: record_id
            }),
            invalidatesTags: ['transaction']
        })
    })
})

export default apiSlice
