import _ from 'lodash'

export function getSum(transaction , type){
    let sum = _(transaction).groupBy("type").map((objs , keys)=>{
        if(!type)return _.sumBy(objs, 'amount')
        return {
            'type': keys,
            'color': objs[0].color,
            'total': _.sumBy(objs, 'amount')
        }
    }).value()
    return sum
}

export function getLabels(transaction){
    let amountSum = getSum(transaction , 'type')
    let Total = _.sum(getSum(transaction))
    let percent = _(amountSum).map(objs =>{
        return _.assign(objs , {percent: ( objs.total / Total) * 100})
    }).value()
    return percent
}

export function ChartData(transaction , custom){

    let bg = _.map(transaction, a=> a.color)
    bg = _.uniq(bg)
    let dataValue = getSum(transaction)

    const config = {
        data: {
            datasets: [{
                data: dataValue,
                backgroundColor: bg,
                hoverOffset: 4,
                borderRadius: 30,
                spacing: 10,
            }]
        },
        options: {
            cutout: 115,
        }
    }
    return custom ?? config
}

export function getTotal(transaction){
    return _.sum(getSum(transaction))
}