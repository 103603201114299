import React from 'react'
import {default as api} from '../store/apiSlice.js'
import { getLabels } from '../helper/helper.js'
export default function Labels() {
  const {data , isFetching , isSuccess , isError} = api.useGetLabelsQuery()
  let Transaction;
  if(isFetching){
    Transaction = <div>Fetching</div>
  }else if(isSuccess){
    Transaction = getLabels(data).map((v,i)=>{ return <LabelComponent key={i} data={v}></LabelComponent> })
  }else if(isError){
    Transaction = <div>Error</div>
  }
  return (
    <>
      {Transaction}
    </>
  )
}

function LabelComponent({data}){
    if(!data) return <></>
    return(
        <div className="labels flex justify-between">
            <div className="flex gap-2">
                <div className='w-2 h-2 rounded py-3' style={{background: data.color}}></div>
                <h3 className='text-md'>{data.type ?? ""}</h3>
            </div>
            <h3 className='font-bold'>{isNaN(Math.round(data.percent)) ? 0 : Math.round(data.percent)}%</h3>
        </div>
    )
}