import React from 'react'
import {useForm} from 'react-hook-form'
import List from './List.js'
import {default as api} from '../store/apiSlice.js'

export default function Form() {
    const {register , handleSubmit , resetField} = useForm()
    const [addTransaction] = api.useAddTransactionMutation()
    const onSubmit = async (data) =>{
      if(!data) return {}
      await addTransaction(data).unwrap()
      resetField('name')
      resetField('amount')
    }
  return (
    <div className='form max-w-sm mx-auto w-full'>
      <h1 className="fontbold pb-4 text-xl">Transactions</h1>
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4">
            <div className="input-group">
                <input {...register("name")} required={true} type="text" placeholder='Rent , EMI , Grocery , Bills' className='form-input'/>
            </div>
            <select {...register("type")} className='form-input'>
                <option value="Expense" defaultValue>Expense</option>
                <option value="Investment">Investment</option>
                <option value="Saving">Saving</option>
            </select>
            <div className="input-group">
                <input {...register("amount")} required={true} type="text" placeholder='Amount' className='form-input'/>
            </div>
            <div className="submit-btn">
                <button type='submit' className='border py-2 text-white bg-indigo-500 w-full'>Make Transaction</button>
            </div>
        </div>
      </form>

      <List></List>
    </div>
  )
}
