import React from 'react'
import 'boxicons'
import {default as api} from '../store/apiSlice.js'

export default function List() {
  const {data , isFetching , isSuccess , isError} = api.useGetLabelsQuery()
  const [deleteTransaction] = api.useDeleteTransactionMutation()
  const handleClick = e => {
    if(!e.target.dataset.id) return 0
    deleteTransaction({_id: e.target.dataset.id})
  }
  let Transaction;
  if(isFetching){
    Transaction = <div>Fetching</div>
  }else if(isSuccess){
    Transaction = data.map((v,i)=>{ return <Transtion key={i} category={v} handler={handleClick}></Transtion> })
  }else if(isError){
    Transaction = <div>Error</div>
  }
  return (
    <div className='flex flex-col py-6 ga-3'>
      <h3 className='py-4 text-xl font-bold'>Histroy</h3>
      {Transaction}
    </div>
  )
}

function Transtion({category , handler}){
    if(!category) return null
    console.log(category)
    return(
        <div className="item flex justify-center bg-gray-50 py-2 my-2 rounded-r" style={{borderRight: `8px solid ${category.color}`}}>
            <button className='px-3' onClick={handler}><box-icon data-id={category._id} name="trash" size="18px" color={'black'}></box-icon></button>
            <span className='block w-full'>{category.name ?? ''} - ${category.amount ?? ''}</span>
        </div>
    )
}